import update from 'immutability-helper'
import {LOAD_STACKED_REQUEST} from 'redux/actionTypes.js'
import {LOAD_STACKED_FAILURE} from 'redux/actionTypes.js'
import {LOAD_STACKED_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_EMAIL_IDENTITY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_EMAIL_IDENTITY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_ACCOUNT_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_ACCOUNT_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_ACCOUNT_SUCCESS} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_POLICY_REQUEST} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_POLICY_FAILURE} from 'redux/actionTypes.js'
import {FETCH_CURRENT_USER_POLICY_SUCCESS} from 'redux/actionTypes.js'
import { SETUP_ACCOUNT_REQUEST } from 'redux/actionTypes.js'
import { SETUP_ACCOUNT_FAILURE } from 'redux/actionTypes.js'
import { SETUP_ACCOUNT_SUCCESS } from 'redux/actionTypes.js'

const initialState = {
  loadStackedStatus: 'requested',
  loadStackedError: '',
  fetchCurrentUserStatus: 'requested',
  fetchCurrentUserError: '',
  currentUser: {},
  fetchCurrentAccountUserStatus: 'requested',
  fetchCurrentAccountUserError: '',
  currentAccountUser: {},
  fetchCurrentEmailIdentityStatus: 'requested',
  fetchCurrentEmailIdentityError: '',
  currentEmailIdentity: {},
  fetchCurrentAccountStatus: 'requested',
  fetchCurrentAccountError: '',
  currentAccount: {},
  fetchCurrentUserPolicyStatus: 'requested',
  fetchCurrentUserPolicyError: '',
  policy: {},
  isStatusBannerShown: true,
  isChecklistSuspended: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_STACKED_REQUEST: {
      return update(state, {
        loadStackedStatus: {$set: 'requested'},
      });
    }

    case LOAD_STACKED_FAILURE: {
      return update(state, {
        loadStackedStatus: {
          $set: 'error',
        },
        loadStackedError: {$set: action.payload.error},
      });
    }

    case LOAD_STACKED_SUCCESS: {
      return update(state, {
        loadStackedStatus: {$set: 'success'},
        currentUser: {
          $set: action.payload.currentUser,
        },
        currentAccountUser: {
          $set: action.payload.currentUser.currentAccountUser,
        },
        currentAccount: {
          id: {$set: action.payload.currentUser.currentAccount.id},
          name: {$set: action.payload.currentUser.currentAccount.name},
          logoUrl: {$set: action.payload.currentUser.currentAccount.logoUrl},
          typeName: {$set: action.payload.currentUser.currentAccount.typeName},
          subscription: {$set: action.payload.currentUser.currentAccount.subscription},
          mortgageBrokerAccount: { $set: action.payload.currentUser.currentAccount.mortgageBrokerAccount },
          counters: { $set: action.payload.currentUser.currentAccount.counters },
        },
        currentEmailIdentity: {
          $set: action.payload.currentUser.emailIdentity,
        },
        policy: {
          $set: action.payload.currentUser.policy,
        },
      });
    }

    case FETCH_CURRENT_USER_REQUEST: {
      return update(state, {
        fetchCurrentUserStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_USER_FAILURE: {
      return update(state, {
        fetchCurrentUserStatus: {
          $set: 'error',
        },
        fetchCurrentUserError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_USER_SUCCESS: {
      return update(state, {
        fetchCurrentUserStatus: {$set: 'success'},
        currentUser: {
          $set: action.payload.currentUser,
        },
      });
    }

    case FETCH_CURRENT_EMAIL_IDENTITY_REQUEST: {
      return update(state, {
        fetchCurrentEmailIdentityStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_EMAIL_IDENTITY_FAILURE: {
      return update(state, {
        fetchCurrentEmailIdentityStatus: {
          $set: 'error',
        },
        fetchCurrentEmailIdentityError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_EMAIL_IDENTITY_SUCCESS: {
      return update(state, {
        fetchCurrentEmailIdentityStatus: {$set: 'success'},
        currentEmailIdentity: {
          $set: action.payload.emailIdentity,
        },
      });
    }

    case FETCH_CURRENT_ACCOUNT_REQUEST: {
      return update(state, {
        fetchCurrentAccountStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_ACCOUNT_FAILURE: {
      return update(state, {
        fetchCurrentAccountStatus: {
          $set: 'error',
        },
        fetchCurrentAccountError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_ACCOUNT_SUCCESS: {
      return update(state, {
        fetchCurrentAccountStatus: {$set: 'success'},
        currentAccount: {
          $set: action.payload.currentAccount,
        },
      });
    }

    case FETCH_CURRENT_USER_POLICY_REQUEST: {
      return update(state, {
        fetchCurrentUserPolicyStatus: {$set: 'requested'},
      });
    }

    case FETCH_CURRENT_USER_POLICY_FAILURE: {
      return update(state, {
        fetchCurrentUserPolicyStatus: {
          $set: 'error',
        },
        fetchCurrentUserPolicyError: {$set: action.payload.error},
      });
    }

    case FETCH_CURRENT_USER_POLICY_SUCCESS: {
      return update(state, {
        fetchCurrentUserPolicyStatus: {$set: 'success'},
        policy: {
          $set: action.payload.policy,
        },
      });
    }

    case SETUP_ACCOUNT_REQUEST: {
      return state
    }

    case SETUP_ACCOUNT_FAILURE: {
      return state
    }

    case SETUP_ACCOUNT_SUCCESS: {
      return state
    }
  
    default:
      return state;
  }
}
