import React from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {SubmitButton} from 'buttons'
import {TextInput} from 'forms'
import {CheckboxInput} from 'forms'
import {setupAccount} from 'redux/actions/profile/setupAccount'
import {joinAccount} from 'redux/actions/profile/joinAccount'
import {connect} from 'react-redux'
import {useSnackbar} from 'notistack'
import styled from 'styled-components'
import { useCurrentUser } from 'functions'

const Wrapper = styled.div`
  padding-bottom: ${props => props.theme.space.l};
`

const Label = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.space.m};
  padding-bottom: ${props => props.theme.space.m};
`
const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/

const FormSchema = Yup.object().shape({
  accountName: Yup.string().required('Required field *'),
  firstName: Yup.string().required('Required field *'),
  lastName: Yup.string().required('Required field *'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
  termsAndConditions: Yup.bool().oneOf([true], 'Must accept terms'),
  processPropertyInvestmentData: Yup.bool().oneOf([true], 'Must grant consent'),
})

function SetupAccountForm(props) {
  const {enqueueSnackbar} = useSnackbar()

  let currentUser = useCurrentUser()
  let currentAccount = currentUser.currentAccount || {}
  let currentAccountUser = currentUser.currentAccountUser || {}
  let signUpDetail = currentUser.signUpDetail || {}
  let marketingSource = signUpDetail.marketingSource
  let tenant = currentAccountUser.tenant || {}

  function handleResponse(response) {
    if (response.status === 'success') {
      enqueueSnackbar('Account updated successfully!')
      window.location.reload()
    } else if (response.status === 'error') {
      enqueueSnackbar(response.error, {variant: 'error'})
      window.setTimeout(function () {
        window.location.reload()
      }, 1000)
    }
  }

  let termsAndConditionsLabel = (
    <span>
      I accept the{' '}
      <a href="/terms-and-conditions" rel="noopener" target="_blank">
        terms & conditions
      </a>
    </span>
  )

  function renderTeamName() {
    if (currentAccount.name) {
      return (
        <TextInput
          required
          disabled
          label="Brokerage name"
          name="accountName"
          type="string"
        />
      )
    } else {
      return(
        <TextInput 
          label="Brokerage name" 
          name="accountName" 
          type="string" 
          toolTipContent="The name of your mortgage brokerage" 
          required
        />
      )
    }
  }

  function renderPhone() {
    if (marketingSource !== 'invited_to_team') {
      return(
        <TextInput
          label="Phone (international format e.g. +44)"
          name="phone"
          type="string"
          placeholder="+44"
          required
        />
      )
    }
  }

  function submitButtonText() {
    if (marketingSource === 'invited_to_team') {
      return 'Join team'
    } else if(marketingSource === 'added_as_tenant') {
      return 'Create my account'
    } else {
      return 'Complete account setup'
    }
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          accountName: currentAccount.name || '',
          firstName: currentAccountUser.firstName,
          lastName: currentAccountUser.lastName,
          termsAndConditions: false,
          currentStage: signUpDetail.currentStage,
          processPropertyInvestmentData: tenant.id ? true : false,
          interestedInStackingDeals: false,
          interestedInRaisingPublicFinance: false,
          interestedInRaisingPrivateFinance: false,
          interestedInPortfolioManagement: false,
          interestedInWorkflows: false,
          interestedInTraining: false,
          interestedInCommunity: false,
          interestedInCoaching: false,

        }}
        validationSchema={FormSchema}
        onSubmit={(values) => {
          if (currentAccount.name) {
            props.joinAccount(values, handleResponse)
          } else {
            props.setupAccount(values, handleResponse)
          }
        }}
      >
        {({isSubmitting, setFieldValue, values}) => (
          <Form>
            {renderTeamName()}

            <TextInput label="First name" name="firstName" type="string" required />

            <TextInput label="Last name" name="lastName" type="string" required />

            {renderPhone()}

            <Label>
              Please help us to protect your data & respect your privacy
            </Label>

            <CheckboxInput
              label="I consent to my data being processed for property investment activities"
              name="processPropertyInvestmentData"
              toolTipContent="This is to ensure we comply with the General Data Protection Regulations (GDPR)"
              checked={values.processPropertyInvestmentData}
              handleChange={() =>
                setFieldValue(
                  'processPropertyInvestmentData',
                  !values.processPropertyInvestmentData,
                )
              }
            />

            <CheckboxInput
              label={termsAndConditionsLabel}
              name="termsAndConditions"
              checked={values.termsAndConditions}
              handleChange={() =>
                setFieldValue('termsAndConditions', !values.termsAndConditions)
              }
            />

            <SubmitButton content={submitButtonText()} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const mapDispatchToProps = {
  setupAccount: setupAccount,
  joinAccount: joinAccount,
}

export default connect(null, mapDispatchToProps)(SetupAccountForm)
