import React from 'react'
import Container from 'react-bootstrap/Container'
import {PublicTopNavbar} from 'navs'
import {Card} from 'cards'
import SetupAccountForm from 'spas/SetupAccount/SetupAccountForm.js'
import {Page} from 'pages'
import {Heading} from 'forms'
import {FormWrapper} from 'forms'

function SetupAccount(props) {
  return (
    <Container fluid>
      <PublicTopNavbar />
      <Page>
        <FormWrapper>
          <Card backgroundColor={props => props.theme.colors.white}>
            <Heading text="Setup your account" />
            <SetupAccountForm />
          </Card>
        </FormWrapper>
      </Page>
    </Container>
  )
}

export default SetupAccount
