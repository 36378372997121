import update from 'immutability-helper'
import { FETCH_ACCOUNT_MORTGAGE_APPLICATIONS_REQUEST } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { FETCH_ACCOUNT_MORTGAGE_APPLICATIONS_SUCCESS } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { FETCH_ACCOUNT_MORTGAGE_APPLICATIONS_FAILURE } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_APPLICATION_REQUEST } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_APPLICATION_SUCCESS } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { ARCHIVE_MORTGAGE_APPLICATION_FAILURE } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { DELETE_MORTGAGE_APPLICATION_REQUEST } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { DELETE_MORTGAGE_APPLICATION_SUCCESS } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'
import { DELETE_MORTGAGE_APPLICATION_FAILURE } from 'v2/spas/account/mortgage-applications/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  account: {},
  mortgageApplications: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_MORTGAGE_APPLICATIONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_APPLICATIONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_MORTGAGE_APPLICATIONS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        account: {
          $set: action.payload.account,
        },
        mortgageApplications: {
          $set: action.payload.account.mortgageBrokerAccount.mortgageApplications
        },
      })
    }

    case ARCHIVE_MORTGAGE_APPLICATION_REQUEST: {
      return state
    }

    case ARCHIVE_MORTGAGE_APPLICATION_FAILURE: {
      return state
    }

    case ARCHIVE_MORTGAGE_APPLICATION_SUCCESS: {
      let index = state.mortgageApplications.findIndex(x => x.id === action.payload.mortgageApplication.id)

      return update(state, {
        mortgageApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_MORTGAGE_APPLICATION_REQUEST: {
      return state
    }
    
    case DELETE_MORTGAGE_APPLICATION_FAILURE: {
      return state
    }
    
    case DELETE_MORTGAGE_APPLICATION_SUCCESS: {
      let index = state.mortgageApplications.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        mortgageApplications: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
