import { SETUP_ACCOUNT_REQUEST } from 'redux/actionTypes.js'
import { SETUP_ACCOUNT_SUCCESS } from 'redux/actionTypes.js'
import { SETUP_ACCOUNT_FAILURE } from 'redux/actionTypes.js'
import axios from 'axios'
import Cookies from 'js-cookie'

export function setupAccount(values, handleResponse) {
  return function (dispatch) {
    dispatch(setupAccountRequest())

    let variables = {
      accountName: values.accountName,
      accountType: 'mortgage_broker',
      firstName: values.firstName,
      lastName: values.lastName,
      termsAndConditions: values.termsAndConditions,
      processPropertyInvestmentData: values.processPropertyInvestmentData,
      phone: values.phone,
      currentStage: 'village'
    }

    let query = `
      mutation(
        $accountName: String!, 
        $accountType: String!, 
        $firstName: String!, 
        $lastName: String!, 
        $termsAndConditions: Boolean!, 
        $processPropertyInvestmentData: Boolean!,
        $phone: String!,
        $currentStage: String!,
      ) {
        setupAccount(
          accountName: $accountName
          accountType: $accountType
          firstName: $firstName
          lastName: $lastName
          termsAndConditions: $termsAndConditions
          processPropertyInvestmentData: $processPropertyInvestmentData
          phone: $phone
          currentStage: $currentStage
        ) {
          id
        }
      }
    `

    return axios({
      url: process.env.REACT_APP_API_PATH + 'graphql',
      method: 'post',
      headers: {
        Authorization: Cookies.get('jwtToken'),
      },
      data: {
        variables: variables,
        query: query,
      },
    })
      .then(res => {
        if (res.data.errors) {
          let error = res.data.errors[0].message
          dispatch(setupAccountFailure(error))
          handleResponse({ status: 'error', error: error })
        } else {
          dispatch(setupAccountSuccess())
          handleResponse({
            status: 'success',
          })
        }
      })
      .catch(error => {
        dispatch(setupAccountFailure())
        handleResponse({ status: 'error', error: error.message })
      })
  }
}

export const setupAccountRequest = () => ({
  type: SETUP_ACCOUNT_REQUEST,
})

export const setupAccountSuccess = () => ({
  type: SETUP_ACCOUNT_SUCCESS,
})

export const setupAccountFailure = error => ({
  type: SETUP_ACCOUNT_FAILURE,
  payload: {
    error: error,
  },
})
